<template>
  <div class="register left-float-top ml-30">
    <div class="flex-1">
      <div style="text-align: center;font-size: 16px; margin-bottom: 30px; margin-top: 30px;">我的认证足迹</div>
      <div style="margin-top: 20px;">
        <el-table class="mb-20" :data="data" row-key="id" border empty-text="暂无信息." >
          <el-table-column prop="ctime" label="日期" width="200"/>
          <el-table-column prop="title" label="认证足迹" width="750"/>
        </el-table>
      </div>
    </div>

  </div>
</template>

<script>
import {getOrganDetail} from "@/api";

export default {
  name: "CertificationFootprint",
  data(){
    return{
      organId:'',
      data:[],

    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('cpis-web_userInfo'))) {
      this.organId = JSON.parse(localStorage.getItem('cpis-web_userInfo')).id
    }
    this.searchAction();
  },
  methods:{

    searchAction:function (){

      this.loadGrid()

    },
    loadGrid:function (){
      const self=this;
      let params={organId:self.organId,type:'FOOTPRINT'}
      this.$axios.get('/api/customer/cert/list', {params:params})
          .then((res) => {
            if(res.msg.flag == 0){
              if (null === res.biz || res.biz.empty) {

              } else {
                self.data=res.biz;
              }
            }else{
              self.$message.error({content: res.msg.message, duration: 2});
            }
          }).catch(() => {
      });
    },
  }
}
</script>

<style scoped>

</style>